import 'touchtap-event';

import { MainMenuOptions } from 'src/frontend/commons/exports/main_menu';
import 'src/frontend/preamble';

import { CookieControlOptions } from './components/cookie_banner/interfaces';
import { CalendarIntegrationsOptions } from './exports/calendar_integrations/interfaces';
import { ConfirmationDialogOptions } from './exports/dialogs/interfaces';

const createSwitch = (container: HTMLElement, options) => {
  void import(
    /* webpackChunkName: "components_switch" */ './exports/switch/index'
  ).then(module => module.default(container, options));
};

const createCalendarIntegrationsPage = (
  options: CalendarIntegrationsOptions
) => {
  void import(
    /* webpackChunkName: "components_calendar_settings" */ './exports/calendar_integrations/settings/index'
  ).then(module => module.default(options));
};

const lazyLoadDialogs = () =>
  import(
    /* webpackChunkName: "components_dialogs" */ './exports/dialogs/index'
  );

const createConfirmationDialog = (options: ConfirmationDialogOptions) => {
  void lazyLoadDialogs().then(module =>
    module.createConfirmationDialog(options)
  );
};

const createCookieControl = (
  container: Element,
  options: CookieControlOptions
) => {
  void import(
    /* webpackChunkName: "components_cookie_control" */ './exports/cookie_control'
  ).then(module => module.default(container, options));
};

const createProfileMenu = (container, options, passedStore) => {
  void import(
    /* webpackChunkName: "components_profile_and_gift" */ './exports/profile_and_gift'
  ).then(module => module.createProfileMenu(container, options, passedStore));
};

const createGiftDropdown = (container, options, passedStore) => {
  void import(
    /* webpackChunkName: "components_profile_and_gift" */ './exports/profile_and_gift'
  ).then(module => module.createGiftDropdown(container, options, passedStore));
};

const createMainMenu = (container: Element, options: MainMenuOptions) => {
  void import(/* webpackChunkName: "main_menu" */ './exports/main_menu').then(
    module => module.createMainMenu(container, options)
  );
};

// export to global scope
if (!window.RGComponents) window.RGComponents = {};

Object.assign(window.RGComponents, {
  createProfileMenu,
  createGiftDropdown,
  createCookieControl,
  createCalendarIntegrationsPage,
  createSwitch,
  createConfirmationDialog,
  createMainMenu,
});
